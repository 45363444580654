<template>
	<div id=""><swiper-table :class_id="4"></swiper-table></div>
</template>
<script>
import swiperTable from '@/components/swiper-table.vue';
export default {
	components: { swiperTable },
	data() {
		return {};
	},
	computed: {},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="less"></style>
