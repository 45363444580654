<template>
	<div class="garden-swiper">
		<div class="add-swiper-btn">
			<a-button type="primary" @click="showAddSwiper">
				<a-icon type="plus-circle" />
				<span>添加轮播</span>
			</a-button>
		</div>
		<div class="swiper-list">
			<div class="tr">
				<div class="td index-td">
					<span>排序</span>
				</div>
				<div class="td head-td">
					<span>预览图</span>
				</div>
				<div class="td td-flex">
					<span>配置内容</span>
				</div>
				<div class="td td-flex">
					<span>状态</span>
				</div>
				<div class="td control-td">
					<span>操作</span>
				</div>
			</div>	
			<ul class="swiper-ul">
				<vuedraggable v-model="list" :animation="300" @end="onEnd">
					<transition-group>
						<li class="rol-li" v-for="(item,index) in list" :key="item.banner_id" style="background: #fff;"> 
							<div class="index-td td">
								<span>{{index+1}}</span>
							</div>	
							<div class="td head-td">
								<div class="head-img-box">
									<img :src="$imglink+item.resource_id+'&res_type=mfit_l'" >
								</div>
							</div>
							<div class="td-2 td">
								<span>{{item.page_name}}</span>
							</div>		
							<div class="td-3 td">
								<span>{{item.is_show == 1 ? '已上架' : '已下架'}}</span>
							</div>		
							<div class="td-4 td control-td">
								<div class="control-box">
									<a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
									<a-button size="small" type="danger" @click="toggleShow(item.banner_id, item.is_show)">{{item.is_show == 1?'下架':'上架'}}</a-button>
									<a-popconfirm title="确认删除该轮播" ok-text="是" cancel-text="否" @confirm="deleteSwiper(item.banner_id)">
										<a-button :disabled="item.count > 0" size="small" type="primary" :loading="deling"><a-icon style="font-size: 20px;" type="delete" /></a-button>
									</a-popconfirm>
								</div>
							</div>
						</li>
					</transition-group>
				</vuedraggable>
			</ul>
		</div>		
		
		<a-modal class="edit-swiper-modal" v-model="swiperModal" :title="editSwiper.id ? '修改轮播' : '添加轮播'" okText="提交" cancelText="取消" 
		@ok="confirmSwiper"
		:confirmLoading="editIng"
		@cancel="cancelSwiper">
			<div class="img-box">
				<a-upload 
				class="up-swiper"
				:action="$domain + '/api/resource/uploadImg'" 
				list-type="picture-card" 
				:showUploadList="false"
				@change="handleChange">
				<div class="img-box" v-show="form.resource_id">
					<div class="img-mask">
						<div class="icon" @click.stop="deleteImg">
							<a-icon type="delete" />
						</div>
					</div>
					<img :src="$imglink + form.resource_id" >
				</div>
					<div v-show="form.resource_id == ''">
						<a-icon type="plus" v-if="!uploading"/>
						<a-icon type="loading" v-else/>
						<div class="ant-upload-text">750 * 340</div>
					</div>
				</a-upload>
			</div>
		<!-- 	<div class="sort-box" v-if="editSwiper.id">
				<div class="sub-tl">排序</div>
				<a-input-number class="num-box" id="inputNumber" v-model="form.sort" :min="0" />
			</div> -->
			<div class="link-box">
				<div class="sub-tl">链接</div>
				<a-cascader style="width: 100%;" v-if="loaded" 
					v-model="linkIdArr"
					:options="linkData" 
					:load-data="getLink" 
					placeholder="选择连接" 
					:changeOnSelect="true" 
					@change="linkChange" />
			</div>
		</a-modal>
	</div>
</template>
<script>
	import vuedraggable from "vuedraggable";
export default {
	components:{vuedraggable},
	props: {
		class_id: {
			type: [String,Number],
			default: 0
		}
	},
	data() {
		return {
			editIng: false,
			dataLoading: false,
			swiperModal: false,
			editSwiper: {
				id: '',
				src: '',
				link: ''
			},
			fileList: [],
			loaded: false,
			linkData: [],
			linkIdArr: [],
			options: [],
			list: [],
			page: {
				current: 1,
				total: 0
			},
			uploading: false,
			deling: false,
			form:{
				resource_id: '',
				banner_class: this.class_id,
				page_key: '',
				page_name: '',
				class_id: '',
				class_name: '',
				param_id: '',
				param_name: '',
				is_show: 1,
				sort: 1
			}
		};
	},
	computed: {},
	async created() {
		let link1 = await this.$post('pageList');
		console.log(link1)
		this.linkData = link1.data.map(item => {
			let a = {
				value: item.code+',,'+item.name,
				isLeaf: false,
				label: item.name,
				level: item.level
			};
			return a;
		});
		this.loaded = true;
		this.getSwiper()
	},
	mounted() {},
	methods: {
		onEnd() {
			console.log(this.list)
			let arr = this.list.map(item => {
				return item.banner_id
			})
			this.$post('bannerOrder',{orderList: arr})
		},
		getSwiper() {
			let t = this
			t.dataLoading = true
			t.$post('bannerList',{banner_class: t.class_id,limit: 9999}).then(res => {
				let { code, data, msg } = res
				if (code == 0) {
					t.list = data.items
					t.total = data.total
				} else {
					t.$message.error(msg, 1.5)
				}
				t.dataLoading = false
			}).catch(err => {
				t.dataLoading = false
			})
		},
		onChange(value) {
			console.log(value);
		},
		showAddSwiper() {
			this.swiperModal = true;
			
		},
		handleChange(res) {
			if(res.file.status == 'uploading') {
				this.uploading = true
			}
			if(res.file.status == 'done') {
				this.uploading = false
				this.form.resource_id = res.file.response.data.resource_id
			}
		},
		getLink(selectedOptions) {
			let t = this;
			const targetOption = selectedOptions[selectedOptions.length - 1];
			console.log(targetOption);
			let gdata = {};
			if (targetOption.level == 1) {
				gdata = {
					type: 2,
					page_key: targetOption.value.split(',,')[0]
				};
			} else if (targetOption.level == 2) {
				gdata = {
					type: 3,
					page_key: this.linkIdArr[0].split(',,')[0],
					class_id: targetOption.value.split(',,')[0]
				};
			}

			t.$post('pageList', gdata).then(res => {
				let { code, data, msg } = res;
				if (code == 0) {
					console.log(data);
					let sub = [];
					for (let i = 0; i < data.length; i++) {
						console.log('判断是否没有了：',data[i].level > 2 || (data[i].level == 2 && gdata.page_key == 3),)
						sub.push({
							value: data[i].code+',,'+data[i].name,
							isLeaf: data[i].level > 2 || (data[i].level == 2 && gdata.page_key == 4),
							label: data[i].name,
							level: data[i].level
						});
					}
					targetOption.children = sub;
					this.linkData = [...this.linkData];
					targetOption.loading = false;
				} else {
					t.$message.error(msg, 1.5);
				}
			})
			.catch(err => {});
		},
		linkChange(e) {
			// this.swiper.page_id = e[0];
			// this.swiper.param = e[1];
			this.linkIdArr = e;
			console.log(e)
			for(let i = 0;i < 3; i++) {
				if(e[i]) {
					console.log('213123')
					if(i == 0) {
						this.form.page_key = e[i].split(',,')[0]
						this.form.page_name = e[i].split(',,')[1]
					}else if(i == 1) {
						this.form.class_id = e[i].split(',,')[0]
						this.form.class_name = e[i].split(',,')[1]
					} else {
						this.form.param_id = e[i].split(',,')[0]
						this.form.param_name = e[i].split(',,')[1]
					}
				}else {
					console.log('zhi')
					if(i == 0) {
						this.form.page_key = ''
						this.form.page_name = ''
					}else if(i == 1) {
						this.form.class_id = ''
						this.form.class_name = ''
					} else {
						this.form.param_id = ''
						this.form.param_name = ''
					}
				}
			}
			console.log(this.form.page_key,this.form.page_name, this.form.class_id,this.form.class_name,this.form.param_id,this.form.param_name)
		},
		toggleShow(id,is_show) {
			let t = this
			if(t.showIng) {
				return 
			}else{
				t.showIng = true
			}
			t.$post('bannerFabu',{banner_id: id}).then(res => {
				t.showIng = false
				let { code, data, msg } = res
				if (code == 0) {
					let str = is_show == 1 ? '已关闭' : '已启用'
					t.$message.success(str,1.5)
				    t.getSwiper()
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.showIng = false
			})
		},
		toAddSwiper() {
			this.$router.push({ path: '/gardenDetail' });
		},
		deleteImg() {
			this.form.resource_id = ''
		},
		async toEdit(item) {
			let t = this
			this.swiperModal = true
			this.editSwiper.id = item.banner_id
			this.form.resource_id = item.resource_id
			this.form.page_key = item.page_key
			this.form.page_name = item.page_name
			this.form.class_id = item.class_id
			this.form.class_name = item.class_name
			this.form.param_id = item.param_id
			this.form.param_name = item.param_name
			this.linkIdArr = []
			if(item.page_key) {
				this.linkIdArr.push(item.page_key +',,'+item.page_name)
				if(item.class_id) {
					this.linkIdArr.push(item.class_id+',,'+item.class_name)
					if(item.param_id) {
						this.linkIdArr.push(item.param_id +',,'+ item.param_name)
					}
				}
			}
			console.log('xiugai', this.linkIdArr)
			if(this.linkIdArr.length > 1) {
				for(let i =0; i< this.linkData.length;i++) {
					let one_a = 0;
					if(this.linkData[i].value.split(',,')[0] == this.form.page_key) {
						one_a = i
						console.log('one', this.linkData[i])
						const info = await this.getAllLink(t.linkData[i])
						console.log(info)
						let { code, data, msg } = info;
						if (code == 0) {
							console.log(data);
							let sub = [];
							for (let i = 0; i < data.length; i++) {

								console.log('测试item', data[i])
								sub.push({
									value: data[i].code+',,'+data[i].name,
									isLeaf: data[i].level > 2 || (data[i].level == 2 && this.form.page_key == 4),
									label: data[i].name,
									level: data[i].level
								});
							}
							this.linkData[i].children = sub;
							this.linkData = [...this.linkData];
							this.linkData[i].loading = false;
						} else {
							this.$message.error(msg, 1.5);
						}
						
						if(this.linkIdArr.length == 3) {
							console.log('jinlai???' , this.linkData[one_a].children.length)
							let two_a = 0;
							for(let k = 0; k < this.linkData[one_a].children.length; k++) {
								
								if(this.linkData[one_a].children[k].value.split(',,')[0] == this.form.class_id) {
									two_a = k
									console.log('two', this.linkData[one_a].children[k])
									const info = await this.getAllLink(this.linkData[one_a].children[k])
									console.log(info)
									let { code, data, msg } = info;
									if (code == 0) {
										console.log(data);
										let sub = [];
										for (let i = 0; i < data.length; i++) {
											console.log('dsadasdas:::', this.linkData[one_a].page_key)
											sub.push({
												value: data[i].code+',,'+data[i].name,
												isLeaf: data[i].level > 2,
												label: data[i].name,
												level: data[i].level
											});
										}
										this.linkData[one_a].children[k].children = sub;
										this.linkData = [...this.linkData];
										this.linkData[one_a].children[k].loading = false;
										console.log(this.linkData)
									} else {
										this.$message.error(msg, 1.5);
									}
								}
							}
						}
					}
				}
			}
		},
		getAllLink(selectedOptions) {
			let t = this;
			const targetOption = selectedOptions;
			console.log(targetOption);
			let gdata = {};
			if (targetOption.level == 1) {
				gdata = {
					type: 2,
					page_key: targetOption.value.split(',,')[0]
				};
			} else if (targetOption.level == 2) {
				gdata = {
					type: 3,
					page_key: this.linkIdArr[0].split(',,')[0],
					class_id: targetOption.value.split(',,')[0]
				};
			}
		    return t.$post('pageList', gdata)
		},
		confirmSwiper() {
			let t= this
			if(this.form.resource_id == '') {
				return this.$message.warn('请上传图片！', 1.5)
			}
			if(this.linkIdArr.length == 0) {
				return this.$message.warn('请选择链接！', 1.5)
			}
			this.editIng = true
			let data = JSON.parse(JSON.stringify(this.form))
			let url = '', mg = '';
			if(t.editSwiper.id) {
				url = 'BannerEdit'
				mg = '修改成功'
				data.banner_id = t.editSwiper.id
			}else{
				mg = '添加成功'
				url = 'BannerAdd'
			}

			if(this.form.page_key == 4) {
				this.form.type = 2
			}else{
				this.form.type = 1
			}
			
			t.$post(url, data).then(res => {
				t.editIng = false
				let { code, data, msg } = res
				if (code == 0) {
					t.$message.success(mg, 1.5)
					if(t.editSwiper.id == '') {
						t.page.current = 1
					}
					t.swiperModal = false
					t.getSwiper()
					this.initForm()
				} else if(code != -10) {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.editIng = false
			})
		},
		cancelSwiper() {
			this.swiperModal = false
			this.editSwiper.id = ''
			this.initForm()
		},
		initForm() {
			this.form = {
				resource_id: '',
				banner_class: this.class_id,
				page_key: '',
				page_name: '',
				class_id: '',
				class_name: '',
				param_id: '',
				param_name: '',
				is_show: 1,
				sort: 1
			}
			this.linkIdArr = []
		},
		deleteSwiper(id) {
		let t = this
		t.deling = true
		t.$post('bannerDelete',{banner_id: id})
			.then(res => {
				t.deling = false
				let { code, data, msg } = res
				if (code == 0) {
					t.$message.success(msg,1.5)
					t.getSwiper()
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.deling = false
			})
		}
	}
};
</script>

<style lang="less">
.swiper-list {
	.control-td {
		width: 186px;
	}
}
</style>
